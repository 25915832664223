import React from "react"
import { useStaticQuery, Link, graphql } from "gatsby"
import {
  mainMenuItems,
  footerMenuItems,
  socialMenuItems,
} from "./menu-items"
import styled from "styled-components"
import PropTypes from "prop-types"
import { FaTwitter, FaInstagram, FaGithub } from "react-icons/fa"
import ShareIcon from "./shareicon"
import { defaultTitle } from "../../data";


let primary = "26A0A6"

console.log(JSON.stringify(primary))

export const Wrapper = styled.div`
  padding: 28rem 0 4rem 0;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='1440' height='400' preserveAspectRatio='none' viewBox='0 0 1440 400'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1038%26quot%3b)' fill='none'%3e%3cpath d='M 0%2c81 C 144%2c75.8 432%2c46.4 720%2c55 C 1008%2c63.6 1296%2c110.2 1440%2c124L1440 400L0 400z' fill='rgba(71%2c 215%2c 222%2c 0.47)'%3e%3c/path%3e%3cpath d='M 0%2c147 C 57.6%2c168 172.8%2c245.2 288%2c252 C 403.2%2c258.8 460.8%2c181.8 576%2c181 C 691.2%2c180.2 748.8%2c252 864%2c248 C 979.2%2c244 1036.8%2c161 1152%2c161 C 1267.2%2c161 1382.4%2c230.6 1440%2c248L1440 400L0 400z' fill='rgba(25%2c 132%2c 137%2c 0.9)'%3e%3c/path%3e%3cpath d='M 0%2c325 C 96%2c326.8 288%2c343.2 480%2c334 C 672%2c324.8 768%2c272 960%2c279 C 1152%2c286 1344%2c351 1440%2c369L1440 400L0 400z' fill='rgba(38%2c 160%2c 166%2c 1)'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1038'%3e%3crect width='1440' height='400' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e");  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;

  @media (max-width: 1960px) {
    padding: 6rem 0 18rem;
  }
`;

export const Flex = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  @media (max-width: 680px) {
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
`;

export const Details = styled.div`
  @media (max-width: 680px) {
    margin-bottom: 2rem;
  }
`;

const Container = styled.div`
	max-width: 1280px;
	margin: 0 auto;
	width: 90%;

	@media (min-width: 601px) {
		width: 90%;
	}

	@media (min-width: 993px) {
		width: 80%;
	}
`


const FooterStyles = styled.footer`

  background-color: rgba(var(--lightRGB));
  font-family: var(--serif);
  font-weight: 300;
  font-style: normal;
  color: var(--dark);
  //box-shadow: inset 0px 16px 16px -8px rgba(var(--primaryRGB), 0.8);

  .flex {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

      flex-direction: row;
      justify-content: center;

  }

  li {
    list-style: none;
    padding-left: 0;
    margin-top: 5px;
    margin-bottom: 5px;

    @media (min-width: 768px) {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  span {
    text-shadow: 0.4px 0.4px 0 rgba(var(--lightRGB), 0.8);
  }

  a {
    text-decoration: none;
    color: var(--dark);
    text-shadow: 0.4px 0.4px 0 rgba(var(--lightRGB), 0.8);
    transition: var(--transMed);

    &:hover {
      color: var(--primary);
    }
  }

  .brand-cont,
  .brand-cont img {
    margin-bottom: calc(var(--spacing) * 2);
  }

  .brand-cont {
    margin-left: 0;
    margin-right: auto;
    width: 100%;

    a {
      display: inline-block;
    }

    img {
      width: 100%;
    }
  }

  .footer-menu {
    margin: 0;
    width: 50%;
    flex-shrink: 0;
    margin-bottom: calc(var(--spacing) * 2);
    padding: 0;
    margin-bottom: calc(var(--spacing) * 2);

    &:last-child {
      margin-bottom: 0;
    }

    @media (min-width: 375px) {
      width: calc(100% / 3);
      margin-bottom: 0;
    }

    @media (min-width: 768px) {
      flex-basis: 125px;
      flex-grow: 0;
      margin-bottom: 0;

      li {
        padding-left: calc(var(--spacing) / 2);
      }
    }

    @media (min-width: 1200px) {
      flex-basis: 175px;
    }
  }

  .brand-cont,
  .menus-cont,
  .copy-cont {
    width: 100%;
    font-size: 2rem;
  }

  .brand-cont {
    width: 125px;

    @media (min-width: 600px) {
      width: 150px;
      flex-basis: 150px;
      flex-shrink: 0;
      flex-grow: 0;
    }
  }

  .menus-cont {

    margin: calc(var(--spacing) * 2)  0;
    text-shadow: 0.2px 0.2px 0 rgba(var(--darkRGB), 0.9);
    @media (min-width: 375px) {
      display: flex;
    }

      justify-content: center;
  }

  .copy {
    margin: 0;
    padding: 0;
    text-align: center;
    margin-top: calc(var(--spacing) * 2);

    text-decoration: none;
    color: var(--primary);
    //text-shadow: 0.2px 0.2px 0 rgba(var(--darkRGB), 0.9);
    transition: var(--transMed);


    @media (min-width: 600px) {
      margin-top: 0;
    }

    li {
      margin-right: var(--spacing);
    }
  }
`

const Ruler = styled.div`
  margin: 0 auto;
  width: 60%;
  border-bottom: 1px var(--primary) solid;
  text-align: center;
`;

const IconContainer = styled.div`
  border-radius: 50%;
  border: 3px solid var(--dark);
  margin: 0 .5rem;

  &:hover {
    border: 3px solid var(--primary);
  }
  //align-vertical: middle;
`

export const Links = styled.div`
  display: flex;
  align-items: center;

  svg {
    width: 24px;
  }

  a {
    margin: 0 0.5rem;

    &:first-child,
    &:last-child {
      margin: 0;
    }
  }
`;

const Footer = ({ Logo }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            author {
              name
            }
            siteUrl
          }
        }
      }
    `
  )

  const footerData = data.site.siteMetadata

  return (
    <FooterStyles>
      <Wrapper>
        <Flex as={Container}>
          <Details>
            <h2>{defaultTitle}</h2>
            <span>
              © All rights are reserved | {new Date().getFullYear()} | Made with bad CSS
              by{" "}
              <a
                href="https://www.kanonical.io"
                rel="noopener noreferrer"
                target="_blank"
              >
                my awesome husband.
              </a>
            </span>
          </Details>
          <Links>
            <div className="menus-cont">
              <IconContainer>
                <ShareIcon shareUrl={`https://www.instagram.com/justadreamde/`}>
                  <FaInstagram style={{paddingTop: "8px", paddingRight: "4px", paddingLeft: "4px"}}/>
                </ShareIcon>
              </IconContainer>
            </div>
          </Links>
          {/*<Social />*/}
        </Flex>
      </Wrapper>

      {/*
      <div className="flex">

        <Ruler/>

        <div className="menus-cont">
          <IconContainer>
            <ShareIcon shareUrl={`https://twitter.com/mhyrr`}>
              <FaTwitter style={{paddingTop: "12px", paddingRight: "4px", paddingLeft: "4px"}}/>
            </ShareIcon>
          </IconContainer>
          <IconContainer>
            <ShareIcon shareUrl={`https://www.instagram.com/justadreamde/`}>
              <FaInstagram style={{paddingTop: "12px", paddingRight: "4px", paddingLeft: "4px"}}/>
            </ShareIcon>
          </IconContainer>
          <IconContainer>
            <ShareIcon shareUrl={`https://github.com/mhyrr`}>
              <FaGithub style={{paddingTop: "12px", paddingRight: "4px", paddingLeft: "4px"}}/>
            </ShareIcon>
          </IconContainer>
        </div>

        <ul className="copy">

          <li>
            Crafted in Maryland or Delaware with 💦 and garish CSS.
          </li>
          <li>
            <Link to="/about">Colophon and other details.</Link>
          </li>
          <li>
            <a href="mailto:hey@kanonical.io">Hit me up!</a>
          </li>

          {footerData.author.name && (
            <li>
              <a
                href={footerData.siteUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                &copy; {new Date().getFullYear()} {footerData.author.name}
              </a>
            </li>

          )}
        </ul>

      </div>
      */}
    </FooterStyles>
  )
}

Footer.propTypes = {
  Logo: PropTypes.string,
}


export default Footer
